.statistics{

    .card{
        box-shadow: 0px 4px 4px 0px #00000008; 
        margin-top: 10px;
        margin-bottom: 10px;
        height: 120px;
  
        .title{ 
            font-size: .8rem;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #333333;
            @media (min-width: 991px) and (max-width:1251px) {
              font-size: .6rem;
            }
        }

        .subtitle{ 
            font-size: .8rem;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: left;
            color: #808080;
            @media (min-width: 991px) and (max-width:1251px) {
                font-size: .6rem;
              }
        }

        .value{ 
            font-size: 1.3rem;
            font-weight: 500; 
            letter-spacing: 0em;
            text-align: left;
            color: #000000;
            @media (min-width: 768px) and (max-width:1290px) {
                font-size: .9rem;
              }
        }

        .graph{
            text-align: end;
        }
        .bottom-percent{ 
            font-size: .7rem;
            font-weight: 400; 
            text-align: left;
            color: #6CC91A;  
        }

        .bottom-week{ 
            font-size: .7rem;
            font-weight: 400; 
            letter-spacing: 0em;
            text-align: left;
            color: #808080;
            
            
        }
        } 
 }