.AddTransactionOption{
    .card{
        border: 1px solid #DFE4EA;

        .title{ 
            font-size: 1rem;
            font-weight: 500;
            line-height: 32px;
            text-align: left;
            color: #1B1C20; 

        }

        .description{ 
            font-size: .8rem;
            font-weight: 500;
            line-height: 21px;
            text-align: left;
            color: #606060; 

        }

        &:hover{
            border: 1px solid #FC6FBB;
            cursor: pointer;
        }

        &.selected{
            border: 1px solid #FC6FBB;
        }
    }


    .btn{
        width: 100%;
        height: 48px;  
        border-radius: 100px;
        border: 1px 0px 0px 0px;  
        font-size: .9rem; 
        font-weight: 500;
        line-height: 18px;
        text-align: center; 
    }
}