.Profile{
    margin-top: 20px;
    .card{
        box-shadow: 0px 2px 2px 0px #00000008; 
        border-radius: 8px;
        height: 280px;
        .profile-icon{

        }
        .social{
           svg{
            margin: 2px;
           }
        }
        .name{ 
            font-size: 1.5rem;
            font-weight: 500;
            line-height: 32px;
            text-align: left;
            
        }
        .user-role{ 
            font-size: .8rem;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: #868484;

        }
        .card-footer{
            background-color: rgb(255, 255, 255);
            padding: 16px 0px !important;
            .email{ 
                font-size: .8rem;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
                color: #000000; 
            }

            .department{ 
                font-size: .8rem;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
                color: #000000;
            }

            .profile-action{
                .btn{
                    width: 115px;
                    height: 35px; 
                    border-radius: 32px; 
                    border: 1px solid #FC6FBB;
                    color: #FC6FBB;
                    font-size: .8rem;
                }
            }
        }      
    }
}