.Event{
    margin-top: 20px;
    .card{
        box-shadow: 0px 2px 2px 0px #00000008; 
        border-radius: 8px;

        .card-header{ 
            background-color: #fff;
            .title{ 
                font-size: 1rem;
                font-weight: 500;
                line-height: 32px;  
            }

            .see-all{
                color: #FC6FBB; 
                font-size: 14px;
                font-weight: 400;
                line-height: 24px; 
                text-align: end;
            }       
        } 
        
        .items{
            .row{ 
                img{
                    height: 60px;
                    width: 60px;
                }
                
                .md-col{ 
                    border-bottom: 1px solid #D1DEEE;
                    padding-bottom: 10px;
                }

                &:last-child{ 
                    .md-col{  
                       border-bottom: none !important;
                    }
                }

            
            }



            .item-title{ 
                font-size: .80rem;
                font-weight: 400;
                line-height: 20px; 
                color: #000000;
                
            }
            .date{ 
                font-size: .8rem;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
                color: #868484;

            }

            .item-day{ 
                font-size: .8rem;
                font-weight: 400;
                line-height: 24px; 
                color: #00000080; 
                text-align: end;
            }
        }
    }
}