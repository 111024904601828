@import '../../../../Assets/Scss/primaryColor';

.orders {
  margin-bottom: 50px;

  .head-tab {
    height: auto;
    width: fit-content;
    background-color: #ffffff;

    .nav-link {
      background-color: #ffffff;
      color: #808080;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      height: 50px;

      @media (min-width: 600px) {
        margin: 0 20px;
      }
    }
    .active {
      background-color: #ffffff;
      color: #e873a8;
      border-bottom: 2px solid #e873a8;
      border-radius: 0px;
    }
  }

  tbody {
    tr {
      &:hover {
        cursor: pointer;
      }
      td {
        vertical-align: middle;
      }
    }
  }

  .card {
    border-radius: 8px;

    .card-header {
      background: #f5f6fa;
      .title {
        font-size: 1rem;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #1b1c20;
      }

      .search-field {
        position: relative;
        span {
          position: absolute;
          left: 10px;
          top: 8px;
        }
        .form-control {
          height: 40px;
          border: none;
          padding-left: 40px;

          &::placeholder {
            font-size: 0.8rem;
          }
        }
      }

      .btn-filter {
        width: 100%;
        height: 40px;
        border-radius: 8px;
        border: none;
        background: #ffffff;
        font-size: 0.84rem;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        color: #82858a;

        @media (max-width: 764px) {
          margin-top: 10px;
        }

        svg {
          margin-right: 10px;
        }
      }
      .btn-export {
        width: 100%;
        height: 40px;
        border-radius: 8px;
        border: none;
        background: #ffffff;
        font-size: 0.84rem;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        color: #82858a;

        @media (max-width: 764px) {
          margin-top: 10px;
        }

        svg {
          margin-right: 10px;
        }
      }

      .filter-dropdown {
        width: 328px;

        .dropdown-item {
          height: 360px;

          &:hover {
            background-color: #ffffff;
          }

          label {
            font-size: 0.8rem;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
            color: #333333;
            margin-bottom: 8px;
          }
          .btn-reset {
            width: 82px;
            height: 40px;
            border-radius: 50px;
            border: 1px;
            border: 1px solid #d6dee8;
            background: #ffffff;
            font-size: 0.8rem;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0em;
            color: #e873a8;
          }

          .btn-filter {
            width: 82px;
            height: 40px;
            border-radius: 50px;
            border: 1px;
            border: 1px solid #d6dee8;
            background: #e873a8;
            font-size: 0.8rem;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0em;
            color: #ffffff;
          }
        }
      }

      .export-dropdown {
        .dropdown-item {
          padding: 15px 15px;
          border-bottom: 1px solid #f0f1f2;
          &:hover {
            cursor: pointer;
          }
        }
      }

      .dropdown-menu {
        .dropdown-item {
          font-size: 0.8rem;
        }
      }
    }

    .option-dropdown {
      .dropdown-toggle::after {
        display: none;
      }

      .dropdown-item {
        border-bottom: 1px solid #f2f0f0;
        font-size: 0.8rem;
        &:hover {
          cursor: pointer;
        }

        a {
          padding: 10px 0;
          text-decoration: none;
          color: #333333;
        }

        &:focus {
          background-color: #ffffff;
          color: #333333;
        }
        &:active {
          background-color: #ffffff;
          color: #333333;
        }
        &:focus-visible {
          background-color: #ffffff;
          color: #333333;
        }
      }

      .dropdown-menu {
        position: absolute;
        z-index: 999;
      }
    }

    .btn-add-company {
      height: 35px;
      border-radius: 50px;
      border: 1px;
      background-color: #e873a8;
      color: #ffffff;
      font-family: Poppins;
      font-size: 0.8rem;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: center;
      padding: 0 20px;
    }

    .card-body {
      border-radius: 8px;
      border: 1px solid #f4f4f7;
      background-color: #ffffff;

      thead {
        td {
          color: #808080;
          font-size: 0.8rem;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: left;

          input {
            height: 14px;
            width: 14px;
            accent-color: $primary-color;
          }
        }
      }

      tbody {
        td {
          color: #0e1f33;
          font-size: 0.8rem;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: left;

          .status {
            width: 101px;
            height: 28px;
            padding: 5px 12px 5px 13px;
            border-radius: 100px;
            color: #edb82e;
            font-family: Poppins;
            font-size: 0.7rem;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: center;
            background: #edb82e26;
          }
        }
      }

      tbody > tr {
        border-bottom: 1px solid #eaecf0;
      }
      thead > tr {
        border-bottom: 1px solid #eaecf0;
      }

      .addrate {
        width: 105px;
        height: 25px;
        border-radius: 100px;
        border: 1px solid #e873a8;
        background-color: rgb(254, 254, 254);
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        color: #e873a8;
      }
    }
  }

  .card-bottom {
    .btn {
      border: 1px solid #e873a8;
      // padding: 0 35px;
      height: 36px;
      width: 100%;
      top: 951px;
      left: 890px;
      border-radius: 24px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: center;
      color: #e873a8;
    }

    .item-delected {
      display: inline-flex;

      input {
        height: 14px;
        width: 14px;
        accent-color: $primary-color;
      }

      .text {
        margin-top: -3px;
        margin-left: 10px;
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        color: #0e1f33;
      }
    }
  }
}
