.AsignTrip{
    padding-top: 30px;
    padding-bottom: 30px;

    b{ 
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
       
    }
    label{ 
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left; 
        margin-top: 20px;  
    }

    input{
        &:focus{  
            border: 1px solid #DFE4EA !important;
            outline: #E873A8 !important;
        }

        &:active{  
            border: 1px solid #DFE4EA !important;
            outline: #E873A8 !important;
        }
    }
    .btn-asign{
        width: 139px;
        height: 40px;
        border-radius: 50px;
        border: 1px;
        background-color: #E873A8;
        color: white;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em; 
        margin-top: 70px;

    }

 
    .col-md-6:first-child{
        @media (min-width: 600px) {
            border-right: 1px solid #DFE4EA;
        }
    }




    .details-section{
        .card-header{ 
            border-bottom:  1px solid #EAECF0;
            .title{ 
                font-size: .89rem;
                font-weight: 500;
                line-height: 32px;
                letter-spacing: 0em;  
            }

            .sub-title{  
                font-size: .7rem;
                font-weight: 500;
                line-height: 32px;
                letter-spacing: 0em;  
                text-align: right;
            }
        }

        .card-body{
            font-size: .8rem;

            .value{ 
                font-size: .8rem;
                font-weight: 600;
                line-height: 21px;
                letter-spacing: -0.30000001192092896px;
                text-align: right;

            }
            .product-image{
                width: 151.1999969482422px;
                height: 112px;   
                overflow: hidden;
                margin-bottom: 20px;
                border-radius: 10px;
            }
            .seller-name{ 
                font-size: .79rem;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: -0.30000001192092896px; 
                
            }
            .quantity{  
                font-size: .73;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: 0em; 
                color: #82858A; 
            }
        }

        .title-2{ 
            font-size: .8rem;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: -0.30000001192092896px; 
            color: #000000;
            margin-bottom: 10px; 
        }

        .value-2{ 
            font-size: .8rem; 
            line-height: 21px; 

        }

        .view-logistics{ 
            color: #E873A8;
        }

        .download-link{ 
            font-size: .8rem;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
            text-decoration: none;
            color: #E873A8;
            height: 20px;
            display: flex;
            align-items: center;
            white-space: inherit; 
        }
    }
}