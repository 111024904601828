@import "../../../../../Assets/Scss/primaryColor";
.AddProductRate{

    label{
        font-size: .8rem;
        margin-bottom: 10px;
    }

    .btn{
       height: 45px;
       border-radius: 40px;
    }

 

       .company-info{
            width: 481px;
            height: 73px ;
            background: #F5F6FA; 
            display: flex;
            justify-content: flex-start;
            align-items: center; 

            .name{ 
                font-size: 1rem;
                font-weight: 500;
                line-height: 32px;
                letter-spacing: 0em; 
                color: #1B1C20;
                height: 73px ;
                display: flex;
                align-items: center; 
                justify-content: flex-start;
                text-transform: capitalize !important;

            }
            
          
       }
       
       .error-message {
            font-size: .7rem;
       }
}


.HideProductPrice{
    .form-switch 
    { 
        font-size: .9rem;

        .form-check-input {
            width: 3em;
            margin-right: 1.5em;  
            height: 17px; 

            &:checked {
                background-color: $secondary-color;
                border-color: $secondary-color;
            }
        }
        
     }
 }