.createPlp{
    margin-bottom: 50px;
    .card{ 
        border-radius: 8px;
        
    
        .card-header{ 
            background: #F5F6FA;
            .title{ 
                font-size: 1rem;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: 0em;
                text-align: left;
                color: #1B1C20;
                
                @media (max-width: 600px) {
                    margin-bottom: 10px;
                }
            }

            .btn-decline-req{
                width: 100%;
                height: 35px; 
                border-radius: 50px;
                border: 1px solid #FF5050;  
                font-family: Poppins;
                font-size: .8rem;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: 0em; 
                color: #FF5050;


            }

            

              
        }
    }

    .instruction{
        font-size: .8rem;
        color: #E873A8;
        margin: 0 0 20px 0;
    }
    .btn-respond-req{
        width: 150px;
        height: 35px; 
        font-size: .8rem;
        border-radius: 50px;
        border: 1px solid #E873A8;
        background:   #E873A8;
        color: #FFFFFF; 
        
    }

        label{
            font-size: .8rem;
            margin-bottom: 10px;
        }

        .btn{
            height: 35px;
            border-radius: 40px;
            width: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 150px;
        }
    

       .error{
        font-size: .8rem;
       }
  
       .sub-title-wrap{
        font-size: .8rem;
        margin-top: 30px;
       }
    
       .progress{
            height: 5px;
            .progress-bar{
                height: 5px;
            }
        }

        .step-title{ 
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: #000000;  
        }

        .step-page-number{
            font-family: Poppins;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.30000001192092896px; 
            color: #82858A;


        }
}