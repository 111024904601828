.AdminRequest{
    tbody{
        tr{
            &:hover{
                cursor: pointer;
            }
            td{ 
                vertical-align: middle;
            }
        }
    }
    .btn-transaction-detail{ 
        height: 25px; 
        border-radius: 50px;
        border:1px solid #E873A8; 
        background-color: #f2f2f2; 
        background-color: #FFFFFF; 
        color: #363535;
        font-family: Poppins;
        font-size: .8rem;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center; 
        padding: 0 20px;
    }
}