.order-tracking-card-item {
    border:0px;
    padding-left: 0px;
    margin: 0px;
    position: relative;
    .item-title {
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color:#333333;
    }
    .item-value {
        font-weight: 500 !important;
        font-size: 16px;
        line-height: 19px;
        color:#333333;
    }
    .item-icon  {
        background:rgba(237, 184, 46, 0.15);
        border-radius: 100%;
        padding: 9px ;
    }
    .item-icon-green-bg {
        background: rgba(0, 193, 43, 0.15);;
        border-radius: 100%;
        padding: 9px ;
    }


    .dotted-vertical-line {
        transform: rotate(90deg);
        border: 0.1px dashed #E0E0E0;  
    }
    
}

.order-tracking-card-item-invalid {
    border:0px;
    padding-left: 0px;
    margin: 0px;
    .item-title {
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color:#CDCDCD;
    }
    .item-value {
        font-weight: medium;
        font-size: 16px;
        line-height: 19px;
        color:#CDCDCD;
    }
    .item-icon  {
        background:rgba(237, 184, 46, 0.15);
        border-radius: 100%;
        padding: 9px ;
        filter: invert(97%) sepia(8%) saturate(12%) hue-rotate(201deg) brightness(116%) contrast(76%);
    }
    .item-icon-green-bg {
        background: rgba(0, 193, 43, 0.15);;
        border-radius: 100%;
        padding: 9px ;
    }
    .dotted-vertical-line {
        transform: rotate(90deg);
        border: 1.14039px dashed #E0E0E0;
        
    }
    
}


li.order-tracking-card-item:last-child , li.order-tracking-card-item-invalid:last-child  {
   .dotted-vertical-line:last-child {
        border:none
    }
} 