@import "../../../../../Assets/Scss/primaryColor";

.RequestDetails{
    margin-bottom: 50px;
    .card{ 
        border-radius: 8px;
        
    
        .card-header{ 
            background: #F5F6FA;
            .title{ 
                font-size: 1rem;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: 0em;
                text-align: left;
                color: #1B1C20;
                
                @media (max-width: 600px) {
                    margin-bottom: 10px;
                }
            }

            .btn-decline-req{
                width: 100%;
                height: 35px; 
                border-radius: 50px;
                border: 1px solid #FF5050;  
                font-family: Poppins;
                font-size: .8rem;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: 0em; 
                color: #FF5050;


            }

            .btn-respond-req{
                width: 100%;
                height: 35px; 
                font-size: .8rem;
                border-radius: 50px;
                border: 1px solid #E873A8;
                background:   #E873A8;
                color: #FFFFFF;

                
            }

              
        }
    }

    .details-section{
        .card-header{ 
            border-bottom:  1px solid #EAECF0;
            .title{ 
                font-size: .89rem;
                font-weight: 500;
                line-height: 32px;
                letter-spacing: 0em;  
            }

            .sub-title{  
                font-size: .7rem;
                font-weight: 500;
                line-height: 32px;
                letter-spacing: 0em;  
                text-align: right;
            }
        }

        .card-body{
            font-size: .8rem;

            .value{ 
                font-size: .8rem;
                font-weight: 600;
                line-height: 21px;
                letter-spacing: -0.30000001192092896px;
                text-align: right;

            }
            .product-image{
                width: 151.1999969482422px;
                height: 112px;   
                overflow: hidden;
                margin-bottom: 20px;
                border-radius: 10px;
            }
            .seller-name{ 
                font-size: .79rem;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: -0.30000001192092896px; 
                
            }
            .quantity{  
                font-size: .73;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: 0em; 
                color: #82858A; 
            }
        }

        .title-2{ 
            font-size: .8rem;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: -0.30000001192092896px; 
            color: #000000;
            margin-bottom: 10px; 
        }

        .value-2{ 
            font-size: .8rem; 
            line-height: 21px; 

        }
    }
 

    div:where(.swal2-container) .swal2-html-container { 
        font-size: .9em;
    }
}