.AverageMarketPrice{
    .card-header{ 
        font-size: .98rem;
        font-weight: 500;
        line-height: 32px;
        text-align: left;
        background: #000000;

    }

    .market-price-list{
        .field-count{
            background: #F5F6FA; 
            font-family: Poppins;
            font-size: .8rem;
            font-weight: 400;
            line-height: 33.88px; 
            color: #808080; 
            height: 38px; 
            display: flex;
            justify-content: center;
            border-radius: 8px;  

        }

        .field-price{
            width: 100%;
            height: 38px; 
            font-size: .8rem; 
            border-radius: 8px;
            border: 1px 0px 0px 0px; 
            border: 1px solid #EAECF0; 
            color: #0E1F33;

        }

    }

    .btn-add{
        width: 180px;
        height: 40px;  
        border-radius: 50px;
        border: 1px; 
        border: 1px solid #FC6FBB;
        color: #FC6FBB; 
        font-size: .8rem;

    }

    .create-new-price-form{
        .form-control{ 
            border-radius: 50px;
            height: 40px;  
        }

        label{
            font-size: .8rem;
        }

        .error{
            font-size: .8rem;
        }

        .btn{ 
            width: 100%;
            height: 40px;  
            border-radius: 50px;
            border: 1px; 
            border: 1px solid #FC6FBB;
            background-color: #FC6FBB; 
            font-size: .8rem;
        }
    }
}