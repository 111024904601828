.file-upload{
    .upload-container {
        .text {
            font-size: 14px;
        }
        .btn { 
            font-size: .8rem !important;
            color: #FC6FBB !important;
            border: 1px solid #FC6FBB;
            background: #ffffff !important;
            width: 150px !important;
            height: 40px !important;
        }
    }
}

