 
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;1,300;1,400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import './Assets/Scss/primaryColor';

// @tailwind base;
// @tailwind components;
// @tailwind utilities;
/* Default light mode */
:root {
  --background-color: #F5F6FA;
  --text-color: #000000;
}

/* Dark mode */
[data-theme="dark"] {
  --background-color: #121212;
  --text-color: #ffffff;
}
 
html{ 
  font-family: "Poppins", sans-serif !important;
  scroll-behavior: smooth;
}

body{  
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  scroll-behavior: smooth;
  overflow-x: hidden;
  
  background-color: var(--background-color);
  color: var(--text-color);
}

.text-secondary-default{
  color: #7a7a7a !important;
}

.bg-top-dark{
  background-color: #232323;
  border-bottom: 1px solid #232323 !important;
}

.emp-border-end-none { 
  border-right: none !important;
}
.card-border-bottom{ 
  border-bottom: 1px solid #555555 !important;
}

.card-inner-border-none{ 
  border-bottom: 1px solid #55555500 !important;
}

.employee-dark-form{
  border: 1px solid #444444 !important;
  
  &:focus{
    background-color: #444444 !important; 
    color: rgb(111, 111, 111) !important; 
  }

  &:active{
    background-color: #444444 !important; 
    color: rgb(111, 111, 111) !important; 
  }
   

  /* Target input fields that are autofilled */
  &:-webkit-autofill {
    background-color: #242424 !important; /* Override the autofill background color */
    -webkit-box-shadow: 0 0 0px 1000px #242424 inset !important; /* Ensures background color is fully white */
    color: rgb(111, 111, 111) !important; /* Set text color if needed */
  }

  /* For other browsers */
  &:-moz-autofill {
    background-color: #242424 !important;
    color: rgb(111, 111, 111) !important;
  }

  &:-ms-autofill {
    background-color: #242424 !important;
    color: rgb(111, 111, 111) !important;
  }
}

input[type=text], input[type=number], input[type=date], input[type=password]{
  font-size: .7rem !important;  
  height: 46px;
  &:focus{
      outline: none !important;
      box-shadow: none !important;

  }
  &::placeholder{
      font-size: .7rem;  
  }
}


input[type=radio]{
   accent-color: $secondary-color;
   margin-right: 10px; 
}

input[type='datetime-local']::placeholder{ 
  font-size: .7rem !important;   
}
input[type="datetime-local"]{
  font-size: .7rem !important;  
  height: 46px;  
  
  &:focus{
    outline: none !important;
    box-shadow: none !important; 
  }
}


input[type="file"]{
  font-size: .7rem !important;  
  height: 46px;  
  
  &:focus{
    outline: none !important;
    box-shadow: none !important; 
  }
}

select{
  font-size: .7rem !important;  
  height: 46px;
  &:focus{
      outline: none !important;
      box-shadow: none !important;

  }
  &::placeholder{
      font-size: .7rem;  
  }
} 

textarea{
  font-size: .7rem !important;   
  &:focus{
      outline: none !important;
      box-shadow: none !important;

  }
  &::placeholder{
      font-size: .7rem;  
  }
}


button{ 
  &:focus{
      outline: none !important;
      box-shadow: none !important;

  } 
}

.btn-default{
  background-color: $secondary-color;
  color: $white-color;

  &:hover{ 
    background-color: $secondary-color-light;
    color: $white-color;
  }
}

.swal2-confirm.swal2-styled.swal2-default-outline { 
  background-color: $secondary-color !important;
}

.swal2-cancel.swal2-styled.swal2-default-outline{ 
  border: 1px solid $secondary-color !important;
  background-color: rgb(255, 255, 255) !important;
  color: $secondary-color;
}

.swal2-confirm.swal2-styled{
  background-color: $secondary-color !important; 
}

td{ 
  align-items: center;
}