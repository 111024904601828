$dark: rgb(17, 16, 16);  
$primary-color: #0E1F33;
$secondary-color: #E873A8;
$secondary-color-light: #f54091;
$tertiary-color: #2d2045;
 
$white-color: #ffffff;
$white-color-1: #ffffff80;
$white-color-2: #f7f9fc;
$white-color-3: #f7f9fc;
$white-color-4: #f0f0f0;
$white-color-5: #eeeeee;
$white-color-6: #eeeeee;
$white-color-7: #EFF2F6;

$dark-color: #000000;
$dark-color-1: #212121;
$dark-color-2: #bbb8b8;
$dark-color-3: #767676;
$dark-color-4: #00000029;
$dark-color-5: #767676;
$dark-color-6: #333333;
 