@import "./../../../../Assets/Scss/primaryColor";
 
.login{

  background-color: $primary-color;
  background-image: url("../../../../Assets/Images/loginbg.png");
  background-position: bottom;
  background-repeat: no-repeat;
  height: 100vh;
  overflow: scroll;

  @media (max-width: 675px) {
    background-size: cover;
  }

  @media (min-width: 675px) {
    background-size: 80%;
  }

  .logo{
    height: 22px; 
    width: 100px;
  }

  h2{
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em; 
  }

  .bg-tg{
    background-color: $secondary-color;
    transition: all .5s ease-in-out;
    border-radius: 100px;
    border: 1px solid #DFE4EA; 
    color: $white-color;
    text-align: center;
    font-family: Poppins;
    font-size: .9rem;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; 
    padding: 15px 0;
    width: 100px;

    &:hover{
      transform: scale(1.03);
      background-color: $secondary-color-light;
    }
  }

  

  .card{
    margin-top: 200px;
    border-radius: 20px;

      @media (max-width: 575px) {

        margin-top: 130px;
        margin-left: 20px;
        margin-right: 20px;
      }

    label{
      font-size: .8rem;
      font-family: Poppins;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .form-control{
      border: 1px solid #DFE4EA !important;
      border-radius: 10px; 
      font-size: .9rem !important; 
    }

    .password-container{
      position: relative;

      span{
        position: absolute;
        right: 10px;
        bottom: 10px;
        cursor: pointer;
      }


      input[type=password]:not(:placeholder-shown) {
        font-size: 1.8rem !important;
      }
    }  

  }
   
  .copyright{
    text-align: center;  
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em; 
    color: #FFFFFF;
    position: absolute;
    bottom: 50px;
  }
 
}