.UpdatePriceMatrix{
    .title{ 
        font-size: .74rem;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #333333;

    }



    label{
        font-size: .8rem;
        margin-bottom: 10px;
    }

    .btn{
       height: 45px;
    }
}