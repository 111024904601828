@import "../../../../../Assets/Scss/primaryColor";

.LoanProvidersDetails{

    .box_one{ 
        border-top-left-radius: 30px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        background: #0E1F33;

        .title{  
            font-size: 1rem;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: center;
            color: #ffffff; 
            margin-top: 20px;
            border-bottom: 3px solid #E873A8;
    
            @media (max-width: 987px) {
                margin-left: 10px;
            }
        }
        .balance{ 
            font-size: 28px;
            font-weight: 600;
            line-height: 75px;
            letter-spacing: 0em;  
            text-align: center;
            color: #ffffff; 

        }
    }


    .box_two{
  
        border-top-left-radius: 0;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0; 
        background: #0E1F33;

        .title{  
            font-size: 1rem;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: center;
            color: #ffffff; 
            margin-top: 20px;
            border-bottom: 3px solid #E873A8;
    
            @media (max-width: 987px) {
                margin-left: 10px;
            }
        }
        .balance{ 
            font-size: 28px;
            font-weight: 600;
            line-height: 75px;
            letter-spacing: 0em;  
            text-align: center;
            color: #ffffff; 

        }
    }
   
    .box_three{

        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0; 
        background: #0E1F33;

        .title{  
            font-size: 1rem;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: center;
            color: #ffffff; 
            margin-top: 20px;
            border-bottom: 3px solid #E873A8;

            @media (max-width: 987px) {
                margin-left: 10px;
            }
        }
        .balance{ 
            font-size: 28px;
            font-weight: 600;
            line-height: 75px;
            letter-spacing: 0em;  
            text-align: center;
            color: #ffffff; 

        }
    }


   /**
    .amount-disbursed{
        width: 100%;  
        border-radius: 8px;
        border: 1px solid #EAECF0;
        background: #FFF; 
        margin-top: 20px; 
 
        .title{
            font-size: 1.3rem;
            height: max-content;
            top: 10px;   
            font-style: normal;
            font-weight: 500;
            line-height: 32px;  
            border-bottom: 3px solid #E873A8;
            color: #ffffff;
            text-align: center;
              
            @media (min-width:987px) { 
                width: 360px;
                position: absolute;
            }
        }

        .amount{ 
            height: 150px; 
            flex-shrink: 0;
            border-top-right-radius: 30px;
            background: #0E1F33;
            color: #FFF;
            display: flex;
            justify-content: center;
            align-items: end;  
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 18px;
            position: relative;

            @media (max-width:500px) { 
                width: 100%;
            }
            @media (min-width:500px) { 
                width: 260px;
            }
            @media (min-width:1089px) { 
                width: 290px;
            }
            
            @media (min-width:1377px) { 
                width: 360px;
            }

            .balance{
                position: absolute;
                bottom: 15px;
            }
        }

        .amount_one{ 
            height: 150px;  
            border-top-left-radius: 30px;
            background: #0E1F33;
            color: #FFF;
            display: flex;
            justify-content: center;  
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 500;  
            position: relative;

            @media (max-width:500px) { 
                width: 100%;
            }
            @media (min-width:500px) { 
                width: 260px;
            }
            @media (min-width:1089px) { 
                width: 290px;
            }
            
            @media (min-width:1377px) { 
                width: 360px;
            }



            .balance{
                position: absolute;
                bottom: 10px;
            }
        }
    }
 */
    .card-table-title{
       font-size: .9rem;
       font-style: normal;
       font-weight: 500;
       line-height: 32px;  
    }

    .card-table-see{
        font-size: .9rem; 
        font-style: normal;
        font-weight: 500;
        line-height: 32px;  
        color: $secondary-color;
        text-decoration: none;

    }
    thead{
      td{ 
        font-size: .9rem;
      }
    }
    tbody{
        tr{
            &:hover{
                background: #FFF;
                box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
            }
        }
        td{
            font-size: .8rem;
            font-weight: 500;
            vertical-align: middle;
            .status{   
                border-radius: 100px;
                background: #D9F6DF;
                height: 25px; 
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: .8rem; 
            }
    
            .btn-refresh{
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: .8rem; 
                background-color: $secondary-color;
                color: #ffffff;
                border-radius: 40px;
            }

            a{
                text-decoration: none;
            }
        }
    }
    
}