.publicadminWraper{
    .vfd-25{
        margin-top: 20px;
        .vfd-logo{ 
            width: 50px;
        }
    }
    .copyright{
        font-size: .8rem !important;
    }
}