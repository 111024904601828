.AnnouncementForm{
   
        .form-control{ 
            border-radius: 50px;
            height: 45px;  
            padding: 14px 2px 5px 12px; 
        }

        label{
            font-size: .8rem;
        }

        .error{
            font-size: .8rem;
        }

        .create-ann-btn{  
            font-size: .8rem; 
            width: 100%;
            height: 50px; 
            border-radius: 100px;
            border: 1px 0px 0px 0px; 
            font-size: .88rem;
            font-weight: 500;
            line-height: 18px;
            text-align: center;

        } 
 

        .btn-add-anouncement{ 
            font-size: .8rem;  
            height: 50px; 
            border-radius: 100px;
            border: 1px 0px 0px 0px; 
            font-size: .88rem;
            font-weight: 500;
            line-height: 18px;
            text-align: center;
        }

        .ann-field-count{
            background: #F5F6FA; 
            font-family: Poppins;
            font-size: .8rem;
            font-weight: 400;
            line-height: 33.88px; 
            color: #808080; 
            height: 58px; 
            display: flex;
            justify-content: center;
            border-radius: 8px;  

        }
}