.TestMessage{
    textarea{
        min-height: 150px;
        font-size: .8rem;  
        width: 100%;
        &:focus{
            box-shadow: none;
            border: 1px solid rgb(176, 176, 176);
        }
    }
}