.order-tracking-card {
    margin-bottom: 20px;
    .order-tracking-card-header{
        font-weight: bold; 
        background-color: white; 
        font-size: .89rem;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em; 

        // &::after {
        //     border: .1px solid #E0E0E0;
        //     width: 100%;
        //     background-color: black;
        //     display: block;
        //     content: " ";
        //     margin-top:30px ;   
        // }

        span {
            font-size: 16px;
            line-height: 18px;
            font-weight: normal;
        }
    }

  
}