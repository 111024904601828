.document-upload {   
    padding-bottom: 30px;  
    background: #FFFFFF; 
    border-radius: 8px;

    .document-title { 
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        text-align: left;
        height: 20px;
    }

    .document-desc { 
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: left;
        color: #606060;
    }

    .file-upload {  
        border-radius: 100px;
        box-sizing: border-box; 
        cursor: pointer;

        .file-upload-input {
            display: none;
        }

        .file-upload-label { 
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            text-align: left; 
            display: flex-start;
            // align-items: start;
            text-align: left;
            color: #4379EB;
        }
    }
    .btn-upload{
        width: 150px;
        height: 35px; 
        font-size: .8rem;
        border-radius: 50px;
        border: 1px solid #E873A8; 
        color: #E873A8; 
        
    }
}