.pageBreadcrumb{ 
    height: 24px; 
    margin-top: 15px;
    
    .breadcrumb-item{ 
        
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;  

        @media (min-width:600px) {
            font-size: .7rem;
        }  
        @media (max-width:600px) {
            font-size: .6rem;
        }  
       

        & + .breadcrumb-item::before {
            content: "<"; 
            display: none;
        }


        &:nth-child(n+2) + .breadcrumb-item:nth-child(n+2)::before {
            content: " > ";
            font-size: 1rem;
            height: 24px; 
            display: flex;
            align-items: center;
          }
         

        a{
            height: 24px; 
            display: flex;
            align-items: center;
            text-decoration: none;
            color: #E873A8;

            .d-icon{
                font-size: 1.5rem;
                color: black;
            }
            @media (min-width:600px) {
                font-size: .7rem;
            }  
            @media (max-width:600px) {
                font-size: .6rem;
            }  
        }

        .btn{
            height: 24px; 
            display: flex;
            align-items: center;
            text-decoration: none;
            color: #E873A8;
            margin-top: 5px;

            .d-icon{
                font-size: 1.5rem;
                color: black;
            }
            @media (min-width:600px) {
                font-size: .7rem;
            }  
            @media (max-width:600px) {
                font-size: .6rem;
            }  
        }
    }
}