@import './../../../../Assets/Scss/primaryColor';
.BulkSms{ 
    .btn-respond-req{ 
        height: 35px; 
        border-radius: 50px;
        border: 1px; 
        background-color: #E873A8; 
        color: #FFFFFF;
        font-family: Poppins;
        font-size: .8rem;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center; 
        padding: 0 20px;
    }

    .radio-list{
        margin-bottom: 20px;
        margin-top: 20px;

        div{
            font-size:1rem
        }

        label{
            display: block;
        }

    }
   
    label{
        font-size: .8rem;
        font-weight: 500; 
    }

    .recipent{
        font-size: .8rem
    }

    .preview{
        font-size: .8rem;  
        cursor: pointer;
    }

    textarea{
        min-height: 150px;
        font-size: .8rem;  
        width: 100%;
        &:focus{
            box-shadow: none;
            border: 1px solid rgb(176, 176, 176);
        }
    }
    .text-danger{
        font-size: .7rem;
    }

    .character-remaining{
        font-size: .8rem;  
        margin-top: 10px;
    }

    input[type='checkbox']{
        height: 14px;
        width: 14px;
        accent-color: $primary-color;

    }
}