.searchInput{
  display: flex;
  flex-direction: row;

  .selectOption{ 
    height: 40px; 

    &:focus{
      border: none;
    }

    @media (min-width: 500px) { 
      width: 160px;
      margin-right: 10px;
    }

    @media (max-width: 500px) { 
      width: 100%;
    }
  }
  .searchInput-inner{ 
    position: relative;
    width: 100%;
    span{
        position: absolute;
        left:10px;
        top: 8px;
    }
    .form-control{ 
       height: 40px;
       border: none;
       padding-left: 40px;

       &::placeholder{
         font-size: .8rem;
       }
    }

    .btn{
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    .input-contain{
      position: relative;
      width: 100%; 

      button{
        display: flex;
        align-items: center;
        position: absolute !important; 
        right:2px !important;
        top:4px !important;
        color: rgb(145, 143, 143);

        i{
          font-size: .8rem;
        }

        &:hover{ 
            color: rgb(155, 0, 0);   
        } 
      }
    }

  }
}