.CustomizeTruck{
    label{
        font-family: Poppins;
        font-size: .8rem;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 5px; 
    }

    .form-control{ 
        padding: 18px 382px 17px 18px; 
        border: 1px solid rgb(234, 231, 231); 
    }

    span{
        width: max-content;
        height: 28px; 
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 24px;
        background: #FFEDF5; 
        padding: 1px 7px;
        font-size: .8rem;
        margin: 4px;

        svg{
            margin-left: 10px;
        }
    }
}