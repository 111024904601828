@import "../../Assets/Scss/primaryColor";
.loader{
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.705);
    top:0;
    bottom: 0;
    background-attachment: fixed;
    .spinner-border{
        width: 13rem; 
        height: 13rem;
        color:rgb(10, 10, 10)
    }
}