.asignTrucks{
    .card{
        margin-top: 13px;
        margin-bottom: 13px;
        border: 1px solid #EAECF0;
        height: 300px;
        padding-top:10px;
        padding-bottom:10px;

        .card-header{ 
            font-size: .8rem;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left; 
        }

        .card-body{
            font-size: .77rem;
            height: 300px;
            overflow-y: scroll;

        }

        .view-all{
            text-align: end;
            color: black;
            font-size: .7rem;
        }
    }
}