.SalesOverview{
    .card{
        margin-top: 13px;
        margin-bottom: 13px;
        border: 1px solid #EAECF0;
        height: 300px;
        padding-top:10px;
        padding-bottom:10px;

        .card-header{ 
            font-size: .8rem;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left; 
        }
        canvas{
          height: 200px !important;
          width: 100% !important;
          font-size: .5rem !important;
        }
      }
    
    .form-control{
        height: 30px;
    }
}