@import "./../../../Assets/Scss/primaryColor";
.minloader {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
  background-color: rgba(255, 255, 255, 0.89);
  top: 0;
  bottom: 0;
  left: 0;
  background-attachment: fixed;
  .spinner-border {
    width: 13rem;
    height: 13rem;
    color: rgb(10, 10, 10);
  }
}
