@import "../../../../../Assets/Scss/primaryColor";

.EditUsersDetails{
    margin-bottom: 50px;
    .card{ 
        border-radius: 8px;
        
    
        .card-header{ 
            background: #F5F6FA;
            .title{ 
                font-size: 1rem;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: 0em;
                text-align: left;
                color: #1B1C20;
                
                @media (max-width: 600px) {
                    margin-bottom: 10px;
                }
            }

            .btn-decline-req{  

            }

            .btn-respond-req{
                font-family: Poppins;
                font-size: .8rem;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: center;
                color: #00C12B; 
                border: 1px solid #D9F6DF; 
                border-radius: 100px;
                background-color: #D9F6DF;
            }

              
        }
    }

    .details-section{
        .card-header{ 
            border-bottom:  1px solid #EAECF0;
            .title{ 
                font-size: .89rem;
                font-weight: 500;
                line-height: 32px;
                letter-spacing: 0em;  
            }

            .sub-title{  
                font-size: .7rem;
                font-weight: 500;
                line-height: 32px;
                letter-spacing: 0em;  
                text-align: right;
            }
        }

        .card-body{
            font-size: .8rem;

            .value{ 
                font-size: .8rem;
                font-weight: 600;
                line-height: 21px;
                letter-spacing: -0.30000001192092896px;
                text-align: right;

            }
            .profile-image{
                width: 127px;
                height: 127px;
                border-radius: 100px; 
                background: #DFE4EA; 
                overflow: hidden;

                img{
                    width: 100%;
                }
            }
            .seller-name{ 
                font-size: .79rem;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: -0.30000001192092896px; 
                
            }
            .quantity{  
                font-size: .73;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: 0em; 
                color: #82858A; 
            }

           
        }
        .btn-edit{
            border: 1px solid #E873A8;
            width: 76px;
            height: 34px; 
            border-radius: 24px; 
            font-family: Poppins;
            font-size: .7rem;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: center;
            color: #E873A8;

        }

        .btn-allow-debit{

            border: 1px solid #f40000;
            width: 100%;
            height: 34px; 
            border-radius: 24px; 
            font-family: Poppins;
            font-size: .7rem;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: center;
            color: #f40000;
        }
        
        .profile-item{
            width:  100%;
            height: 65px;
            gap: 13px;
            
            .name{
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                line-height: 25px;
                letter-spacing: 0em;
                text-align: left;
                color: #808080;

                
            }
            .value{
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0em;
                text-align: left; 
                color: #000000;

            }
        }
    }
 
}