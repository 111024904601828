.Resources{
    margin-top: 20px;
    .card{
        box-shadow: 0px 2px 2px 0px #00000008; 
        border-radius: 8px;

        .card-header{ 
            background-color: #fff;
            padding-bottom: 0px;

            .title{ 
                font-size: 1rem;
                font-weight: 500;
                line-height: 32px;   
                font-weight: 500;
                line-height: 32px;
                text-align: left;

            }

            .see-all{
                color: #FC6FBB; 
                font-size: 14px;
                font-weight: 400;
                line-height: 24px; 
                text-align: end;
            }       
        }

        .card-body{
            padding-bottom: 30px;
            .resource-item{
                transition: all .5s ease-in-out;
                &:hover{
                    transform: scale(1.02);
                }

                img{
                    border-radius: 15px;
                    transition: all .5s ease-in-out;
                    &:hover{
                        transform: scale(1.05);
                    }
                }
                .label{ 
                    font-size: .8rem;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: left;
                    color: #000000;
                    margin-top: 10px;

                }
            }
        }
    } 
} 
        