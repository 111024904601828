.company{
    margin-bottom: 50px;

    tbody{
        tr{
            &:hover{
                cursor: pointer;
            }
            td{ 
                vertical-align: middle;
            }
        }
    }
    
    .card{ 
        border-radius: 8px;
        
 
        .card-header{ 
            background: #F5F6FA;
            .title{ 
                font-size: 1rem;
                font-weight: 500;
                line-height: 32px;
                letter-spacing: 0em;
                text-align: left;
                color: #1B1C20; 
            }

            .search-field{
                position: relative;
                span{
                    position: absolute;
                    left:10px;
                    top: 8px;
                }
                .form-control{ 
                   height: 40px;
                   border: none;
                   padding-left: 40px;

                   &::placeholder{
                     font-size: .8rem;
                   }
                }
            }

            .dropdown-toggle::after{
                display: none;
            }
            .btn-filter{
                width: 100%;
                height: 40px; 
                border-radius: 8px; 
                border: none;
                background:   #ffffff;
                font-size: .84rem;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0em;
                color: #82858A;

                @media (max-width: 764px) {
                    margin-top: 10px;
                }

                
                svg{
                    margin-right: 10px;
                }
            }
            .btn-export{
                width: 100%;
                height: 40px; 
                border-radius: 8px; 
                border: none;
                background:   #ffffff; 
                font-size: .84rem;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0em; 
                color: #82858A;

                @media (max-width: 764px) {
                    margin-top: 10px;
                }

                svg{
                    margin-right: 10px;
                }
            }
        }

        .btn-add-company{ 
            height: 35px; 
            border-radius: 50px;
            border: 1px; 
            background-color: #E873A8; 
            color: #FFFFFF;
            font-family: Poppins;
            font-size: .8rem;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: center; 
            padding: 0 20px;
        }

        .card-body{  
            border-radius: 8px;
            border: 1px solid #f4f4f7;
            background-color: #ffffff;

            thead{
            th{ 
                    color: #808080;  
                    font-size: .8rem;
                    font-weight: 400;
                    line-height: 21px;
                    letter-spacing: 0em;
                    text-align: left;

                }
            }

            tbody{
                td{
                    color: #0E1F33; 
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    letter-spacing: 0em;
                    text-align: left; 
                    
                    .companyLogo{
                        width: 30px;
                    }

                    .export-dropdown{
                        .dropdown-item{
                            border-bottom:1px solid #f2f0f1;
                            &:hover{ 
                                cursor: pointer;
                             }

                             a{ 
                                padding: 10px 0;
                             }
                             
                        }
                    }

                    .dropdown-toggle::after{
                        display: none;
                    }
 
                    .dropdown-item{
                        padding: 10px 10px;
                        border-bottom:1px solid #f0f1f2;
                        font-size: .8rem;
                        &:hover{ 
                            cursor: pointer;
                            }
        
                            a{
                            text-decoration: none;
                            color: #333333;
                            }
        
                            &:focus{  
                            background-color: #ffffff;
                            color: #333333;
                            }
                            &:active{  
                            background-color: #ffffff;
                            color: #333333;
                            }
                        &:focus-visible{  
                            background-color: #ffffff;
                            color: #333333;
                        }
                    }
        
                    .dropdown-menu{
                        position: absolute; 
                        z-index: 999; 
                    }
             
                }
            }

            tbody > tr{ 
                border-bottom: 1px solid #EAECF0
            }
            thead > tr{ 
                border-bottom: 1px solid #EAECF0
            }
        
            .addrate{
                width: 105px;
                height: 25px; 
                border-radius: 100px; 
                border: 1px solid #E873A8;
                background-color: rgb(254, 254, 254); 
                font-size: .8rem;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: center;
                color: #E873A8; 
            }
        }
    }
}