@import '../../Assets/Scss/primaryColor';
.TGModalAlert{
  .modal-confirm {		
    color: #434e65;
    width: 525px;
  }
  .modal-content {
    padding: 20px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
  }

  .modal {
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .modal.fade:not(.show) {
    opacity: 0;
    transform: translateY(-50%);
  }

  .modal-header {
    background: $primary-color;
    border-bottom: none;   
    position: relative;
    text-align: center; 
    border-radius: 5px 5px 0 0;
    padding: 35px;
    display: flex;
    justify-content: center;
  }


  .icon-box {
    color: #fff;		
    width: 95px;
    height: 95px;
    display: inline-block; 
    z-index: 9; 
    padding: 15px;
    text-align: center;
    font-size: 5rem;
  }
  .icon-box i {
    font-size: 64px;
    margin: -4px 0 0 -4px;
  }

 

  .modal-content {
    padding: 20px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
  }

  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    color: #fff;
    text-shadow: none;
    opacity: 0.5;
  }
   .close:hover {
    opacity: 0.8;
  }

 
 .modal-dialog {
    margin-top: 80px;
  }
 
  .btn,  .btn:active {
    color: #fff;
    border-radius: 4px;
    background: $secondary-color !important;
    text-decoration: none;
    transition: all 0.4s;
    line-height: normal;
    border-radius: 30px;
    margin-top: 10px;
    padding: 6px 20px;
    border: none;
  }
  .btn:hover,  .btn:focus {
    background: $secondary-color !important;
    outline: none;
  }

  .btn span {
    margin: 1px 3px 0;
    float: left;
  }

 .btn i {
    margin-left: 1px;
    font-size: 20px;
    float: right;
  }
  .trigger-btn {
    display: inline-block;
    margin: 100px auto;
  }
}