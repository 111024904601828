
.AddUser{
    label{
        font-size: .8rem;
        margin-bottom: 10px;
    }

    .btn{
       height: 45px;
       border-radius: 40px;
    }

    .photo-default{
        height:60px; 
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color:  #D6DEE8;
        border: 1px solid #D6DEE8;
    }

    .addPhoto  {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;

        }
    .addProfilePhoto{ 
        font-size: .77rem;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        color: #82858A;

       }
}