.CompanyDetails{
    .company-item{ 
        margin-bottom: 20px;
        .name{
            font-size: .8rem;
            font-weight: 600;
        }
        .value{ 
            font-size: 1rem;
        }
    }
}