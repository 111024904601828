@import "../../../../Assets/Scss/primaryColor";



.asc {
  position: relative;
  height: 100vh;
  min-height: 650px;

  .admin-logo{
    width: 100px;
  }


  .scroll-vertical{
    height: 77vh !important;
    overflow-y: scroll;  
    scrollbar-width: thin;
    scrollbar-color: #E873A8 #CFD8DC;
  }

  .scroll-vertical::-webkit-scrollbar {
    width: 2px !important;
  }
 
  .scroll-vertical::-webkit-scrollbar-track {
    background:#CFD8DC; 
    width: 2px !important;
  }

  .scroll-vertical::-webkit-scrollbar-thumb {
    background-color: #E873A8 ;
    border-radius: 6px;
    border: 1px solid #CFD8DC;
    width: 2px !important;
  }
 
  .nav-item { 
    height: 42px;
    margin: 0 13px 0 13px;
    margin-bottom: 10px !important;

    .nav-link {
      font-weight: 400;
      font-size: 0.82rem;
      color: $white-color-1;
      min-height: 38px; 
      display: inline-block;
      width: 100%;
      border-radius: 8px;
      padding-left: 5px;
      padding-right: 5px;

      @media (min-width:600px) { 
        width: 100%;
       }
        @media (max-width:600px) { 
        width: 90%;
      }

      span{
        color: $white-color-1;
        font-size: .9rem;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      svg{
        width:37px;
        display: inline-block;
        path{
          fill: #516F93 !important; 
        } 
      }
      
      &:hover{
        border-radius: 8px;
        background-color: $white-color-1;
      }

       
    }

    .active { 
      border-radius: 10px;
      color: $white-color;
      border-radius: 8px;
      background: $white-color;
      color: #E873A8;
        span{
          color: #E873A8;
          font-style: normal;
          font-weight: 500; 
        }
        svg{
          width:37px;
          path{
            fill: #E873A8 !important; 
          }
        } 

        &:hover{
          background: $white-color;

          svg{
            width:37px;
            path{
              fill: #E873A8 !important; 
            }
          } 
          span{
            color: #E873A8 !important;
            font-style: normal;
            font-weight: 500; 
          }
        }
    }
  }
 

  .bottom-segment{
      position: absolute;
      bottom: 0px;
      width: 100%;
      background: #223244; 
      height: 72px;
      flex-shrink: 0; 

      @media (max-width:600px) {
        img{
          width: 34px !important;
        } 
       }

      .nav-item { 
        height: 42px;
        margin: 0;
        .nav-link {
          &:hover{
            background-color: #223244;
          }
        }
      }

      .name{
        color: #FFF;
        font-family: Poppins;
        font-size: .9rem;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;  
        width: 130px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        
      }

      .role{
        color: rgba(255, 255, 255, 0.50);
        font-family: Poppins;
        font-size: .68rem;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 240% */
      }

      .d-icon{
        font-size: 1.2rem;
      }
  }


  .option-dropdown{
    span{
      cursor: pointer;
    }
    .dropdown-toggle::after{
        display: none;
    }

    .dropdown-item{
        padding: 10px 10px;
        border-bottom:1px solid #f0f1f2;
        font-size: .8rem;
        &:hover{ 
            cursor: pointer;
         }

         a{
            text-decoration: none;
            color: #333333;
         }

         &:focus{  
            background-color: #ffffff;
            color: #333333;
         }
         &:active{  
            background-color: #ffffff;
            color: #333333;
         }
        &:focus-visible{  
            background-color: #ffffff;
            color: #333333;
        }
    }

    .dropdown-menu{
      position: absolute; 
      z-index: 999; 
    }

}

}


.businessProfileModal{
      margin-top: 180px; 
      width: 436px;  
      // .modal-content {
      background: #FFFFFF;
      border: 1px solid #DFE4EA;
      border-radius: 28px;


      .modal-content{
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 28px;
    }
    .form-control{ 
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        height: 50px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px; 
        font-feature-settings: 'liga' off; 
        color: #828282;

      

        &:focus{
            outline: none !important;
            box-shadow: none !important;
        }
    }
    textarea{ 
        background: #FFFFFF;
        border: 1px solid #DFE4EA;
        border-radius: 100px;
        height: 70px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px; 
        font-feature-settings: 'liga' off; 
        color: #828282;

      

        &:focus{
            outline: none !important;
            box-shadow: none !important;
        }

        &::placeholder{
          font-size: .8rem
        }
    }

    label{  
      font-style: normal;
      font-weight: 400;
      font-size: .8rem;
      line-height: 19px; 
      color: #333333; 
    }

    select{
        font-size: .8rem !important;
        option{ 
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: .8rem !important;
            line-height: 18px; 
            font-feature-settings: 'liga' off; 
            color: #828282;
        }

    }
    .desc{
      font-size: .7rem;
      font-weight: 400; 
      line-height: 19px; 
      color: #333333;

    }

    .btn-close {
      height: 12px;
      width: 12px; 
      border: 1px solid grey;
      border-radius: 50px; 
      font-size: .7rem !important;
    }
}