.create-subscription{
      
       .error{
         font-size: .6rem;
       } 
 
        .title{ 
            font-size: 2rem;
            font-weight: 700;
            line-height: 51.76px;
            text-align: left;
            color: #0E1F33;

            
        }
        .tagline{ 
            font-size: .8rem;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #000000; 

        }

        label{
            font-size: .85rem;
            margin-bottom: 8px;
        }

        .btn{
            width: 100%;
            height: 50px;
            background-color: #FC6FBB;
            border-radius: 32.35px;
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            text-align: center;
            color: #FFFFFF;

        } 
}