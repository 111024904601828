.WebsiteControl {
  .btn-add-anouncement {
    height: 35px;
    border-radius: 50px;
    background-color: #ffffff;
    border: 1px solid #fc6fbb;
    color: #fc6fbb;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
  }

  .sidebar {
    .nav-link {
      font-size: 0.9rem;
      font-weight: 500;
      line-height: 32px;
      text-align: left;
      color: #000000;

      &.active {
        color: #fc6fbb;
      }
    }
  }

  .modalWrapper {
    border-radius: 100px;
  }

  table {
    border-top: 1px solid #d1deeeaa !important;

    thead {
      td {
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 27px;
        text-align: left;
        color: #a09f9f;
      }
    }
    tbody {
      td {
        height: 51px;
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 27px;
        text-align: left;
        color: #000000;
      }
    }
  }

  .create-price-a {
    width: 200px !important;
  }
}

.ann-form {
  // border-top: 1px solid #DFE4EA;
  padding-bottom: 20px;

  label {
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    margin-bottom: 8px;
  }
  .create-ann-btn {
    width: 100%;
    height: 50px;
    border-radius: 100px;
    border: 1px solid #fc6fbb;
  }
}
