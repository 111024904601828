.TransactionDetails{
    .profile-image{
        width: 127px;
        height: 127px;
        border-radius: 100px; 
        background: #DFE4EA; 
        overflow: hidden;

        img{
            width: 100%;
        }
    }

    .btn{
        font-size: .8rem;
        height: 37px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .profile-item{
        width:  100%;
        height: 65px;
        gap: 13px;
        
        .name{
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: left;
            color: #808080;

            
        }
        .value{
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left; 
            color: #000000;

        }
    }
}