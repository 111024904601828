.CustomizeProduct{
    .card{
        height: auto;
        background-color: #186ed6;
    
        .addPhoto{
            width: 70px;
            height: 70px; 
            border-radius: 8px;
            border: 1px;
            opacity: 0.15px;
            color: linear-gradient(0deg, #516F93, #516F93),
            linear-gradient(0deg, #D6DEE8, #D6DEE8);
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #D6DEE8;
            cursor: pointer;
            
        }
        div{ 
            font-size: .8rem;
            font-weight: 400;
            line-height: 21px; 
            color: #82858A;

        } 
        .form-control{ 
            border-radius: 20px;
        }

        .btn{
            width: 100px;
            border-radius: 20px;
        }
    }
}