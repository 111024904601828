.DepotPrice {
  .card-header {
    font-size: 0.98rem;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    background: #000000;
  }

  .market-price-list {
    .field-count {
      background: #f5f6fa;
      font-family: Poppins;
      font-size: 0.8rem;
      font-weight: 400;
      line-height: 33.88px;
      color: #808080;
      height: 38px;
      display: flex;
      justify-content: center;
      border-radius: 8px;
    }

    .field-price {
      width: 100%;
      height: 38px;
      font-size: 0.8rem;
      border-radius: 8px;
      border: 1px 0px 0px 0px;
      border: 1px solid #eaecf0;
      color: #0e1f33;
    }
  }

  .btn-add {
    width: 180px;
    height: 40px;
    border-radius: 50px;
    border: 1px;
    border: 1px solid #fc6fbb;
    color: #fc6fbb;
    font-size: 0.8rem;
  }

  .create-new-price-form {
    .form-control {
      border-radius: 50px;
      height: 40px;
    }

    label {
      font-size: 0.8rem;
    }

    .error {
      font-size: 0.8rem;
    }

    .btn {
      width: 100%;
      height: 40px;
      border-radius: 50px;
      border: 1px;
      border: 1px solid #fc6fbb;
      background-color: #fc6fbb;
      font-size: 0.8rem;
    }
  }
}
