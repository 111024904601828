 
@import "../../../../../Assets/Scss/primaryColor";

.PLPDetails{
    margin-bottom: 50px;
    .card{ 
        border-radius: 8px;
        
    
        .card-header{ 
            background: #F5F6FA;
            .title{ 
                font-size: 1rem;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: 0em;
                text-align: left;
                color: #1B1C20;
                
                @media (max-width: 600px) {
                    margin-bottom: 10px;
                }
            }

            .btn-decline-req{
                width: 100%;
                height: 35px; 
                border-radius: 50px;
                border: 1px solid #FF5050;  
                font-family: Poppins;
                font-size: .8rem;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: 0em; 
                color: #FF5050; 
            }

            .order-status{
                width: 80%;
                height: 35px; 
                display: flex;
                justify-content: center;
                align-items: center; 
                border-radius: 100px;
                background: #EDB82E26; 
                font-size: .75rem;
                font-weight: 500; 
                text-align: center;
                
            }

            .btn-respond-req{
                width: 100%;
                height: 35px; 
                font-size: .75rem;
                border-radius: 50px;  
                color: #ffffff;  
                background-color: #E873A8;
                border: 1px solid #E873A8; 
                font-weight: 600;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: center;

                
            }

              
        }


        .error{
            font-size: .8rem;
           }


        .label{ 
            font-size: .9rem;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
            color: #0E1F33;  
        } 
        
        .value{ 
            font-size: .9rem;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            color: #1B1C20; 
        }
    
        .btn{
           height: 45px; 
           border-radius: 40px;
        }

        .title{
            font-size: .9rem;
        }

        .repay{
            margin-top: 30px;
            .repay-btn{ 
                width: fit-content;
                padding-left: 30px;
                padding-right: 30px;
                height: 38px; 
                border-radius: 50px;
                border: 1px;
                border: 1px solid #D6DEE8;
                background: #E873A8;
                color: #fefefe;
                font-size: .9rem;
            }
            
        } 
        
       
    }

   .doc-name{
     font-size: .8rem;
     margin-top: 8px;
   }
    .uploadedDocument{
        height: 100px;
        background: #E873A8; 
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        img{
            position: absolute;
        }
         
        .icons{
            position: absolute;
            border-radius: 10px;
            overflow: hidden;

            .btn{
                width: 30px;
                height: 30px;
                display: flex-inline;
                justify-content: center;
                align-items: center;
                background-color: #ffffff;
                padding: 0px !important;
                margin: 6px;
            }
        }
    }

   .btn-save{
        @media (min-width: 600px) {
            width: 40% !important;
        }
    }
}