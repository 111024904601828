@import "../../../../Assets/Scss/primaryColor";
.adminWraper {  
  background: #F5F6FA;
  @media (min-width:1092px) {
    .col-25{ 
      flex: 0 0 auto;
      width: 17%;

      @media (max-width: 1092px) {
        display: none;
      }
    } 
    .col-85{ 
        flex: 0 0 auto;
        width: 83%;
        height: 100vh;
        overflow-y: scroll;
      } 
  }


  @media (max-width:1092px) {
    .col-25{ 
      flex: 0 0 auto;
      width: 100%;

      @media (max-width: 1092px) {
        display: none;
      }
    } 
    .col-85{ 
        flex: 0 0 auto;
        width: 100%;
      } 
  }
 
}
