.Tag{
    .form-group{ 
        position: relative;
        .searchAutoComplete{
            max-height: 100px;
            background-color: aliceblue; 
            overflow: scroll;
            position: absolute;
            width: 100%;
            z-index: 2;


            .list-group{
                margin-top:5px;
                .list-group-item{
                    cursor: pointer;
                }
            }
        }
         
        label{
           font-size: .8rem;
        }

        .msisdn-error{
            font-size: .6rem;
        }

    }

     
    textarea{
        min-height: 150px;
        font-size: .8rem;  
        width: 100%;
        &:focus{
            box-shadow: none;
            border: 1px solid rgb(176, 176, 176);
        }
    }

    .numOfContact{
        font-size: .6rem;
    }
}